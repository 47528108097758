<template>
  <div id="detailed-trans">
    <vx-card>
    <!-- Create table for consume data -->
    <p>
      جهت دریافت فایل اکسل ریزمصرف ماه جاری، روی دکمه زیر کلیک کنید.
    </p>
      <p >
        <vue-button-spinner
          :is-loading="isLoading"
          :disabled="isLoading"
          >
          <span @click="getExcel()" >
            دریافت فایل اکسل
          </span>

        </vue-button-spinner>

      </p>
      <p v-if="this.excel_url != ''" >
        <a v-if="this.excel_url" target="_blank" class="btn btn-primary block "  :href="`${this.excel_url}`" > دانلود فایل </a>
      </p>
    </vx-card>
  </div>
</template>

<script>
import VueButtonSpinner from 'vue-button-spinner'

export default {
  data () {
    return {
      detailedTransCountPage:0,
      detailedTransPageNumber:1,
      consume : [],
      meta:[],
      isLoading: false,
      status: '',
      excel_url: ''
    }
  },
  components : {
    VueButtonSpinner
  },
  watch : {
    // watch the pagination and re run related method when page changes
    // detailedTransPageNumber () {
    //   this.getDetailedTrans()
    // }
  },
  methods : {
    getExcel () {
      this.isLoading = true
      this.$http.get('billing/transaction/excel')
        .then(response => {
          this.excel_url = response.data.params.excel_url
          this.isLoading = false
          this.status = false // or success
        })
        .catch(error => {
          console.error(error)
          this.isLoading = false
          this.status = true //or error
          this.$vs.notify({text: error.response.data.message, color: 'danger'})

        })
    }
  },
  created () {
    // this.getDetailedTrans()
  }
}
</script>

