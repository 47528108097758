<template>
  <div>
    <div class="w-full text-right mr-2">

      <!-- <router-link to="/dashboard" class="text-sm md:text-base text-grey border border-dashed border-white border-opacity-25 rounded py-2 px-4">بازگشت به داشبورد</router-link> -->
    </div>
    <div class="w-full">

      <b-tabs content-class="mt-3" refs="tabs" >
        <b-tab title="افزایش شارژ" href="charges" @click="tabHash('charges')" :active="$route.hash === '#charges' || $route.hash === ''" >
          <transaction-page></transaction-page>
        </b-tab>
        <b-tab title="فاکتورها" href="invoices" @click="tabHash('invoices')" :active="$route.hash === '#invoices'" >
          <InvoiceManual></InvoiceManual>
        </b-tab>
        <b-tab title="ریزمصرف ماه جاری" href="transaction" @click="tabHash('transaction')" :active="$route.hash === '#transaction'" >
          <invoice-detailed-trans></invoice-detailed-trans>
        </b-tab>
      </b-tabs>

    </div>

  </div>
</template>

<script>
import TransactionPage from './TransactionPage.vue'
import InvoiceManual from './Manual.vue'
import InvoiceDetailedTrans from './DetailedTrans.vue'

export default {
  name: 'paymentManage',
  components: {InvoiceManual, TransactionPage, InvoiceDetailedTrans},
  data () {
    return {
      Transaction: true,
      Invoice: false,
      DetailedTrans: false
    }
  },
  watch () {

  },
  methods: {
    tabHash (val) {
      location.hash = val
    }
  },
  mounted () {

  }
}
</script>
<style>
tbody tr:nth-child(odd) {
  background-color: #090C2B;
  color: #fff;
}

.nav-tabs {
  border: 0;
}

.nav-tabs .nav-link {
  color: #1791da !important;
  margin-right: 10px;
  border: 1px dashed #ffffff78 !important;
  border-radius: 8px !important;
}

.nav-tabs .nav-link:hover {
  color: white !important;
  border-radius: 8px !important;
  border: 1px dashed #1791da !important;
}

[dir] .nav-tabs .nav-link.active,
[dir] .nav-tabs .nav-item.show .nav-link {
  background-color: unset !important;

  color: white !important;
  border-radius: 8px !important;
  border: 1px dashed #1791da !important;
}
</style>
