import { render, staticRenderFns } from "./DetailedTrans.vue?vue&type=template&id=7b8b96b0&"
import script from "./DetailedTrans.vue?vue&type=script&lang=js&"
export * from "./DetailedTrans.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports